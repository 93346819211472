<template>
  <div class="content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
    name:'container'
}
</script>

<style>

</style>