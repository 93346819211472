import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index.vue'
import Login from '@/views/login/index.vue'
import { getToken } from '@/utils/auth'


const Staging = () => import('@/views/staging/index.vue')
const Userlist = () => import('@/views/userlist/index.vue')
const Theme = () => import('@/views/theme/index.vue')
const Advert = () => import('@/views/advert/index.vue')
const AdvertList = () => import('@/views/advert/list/index.vue')
const AdvertManager = () => import('@/views/advert/managerlist/index.vue')

const system = () => import('@/views/system/index.vue')
const classification = () => import('@/views/system/classification/index.vue')
const productClassification = () => import('@/views/system/productClassification/index.vue')
const bannerManagement = () => import('@/views/system/bannerManagement/index.vue')

const Catlist = () => import('@/views/catlist/index.vue')
const Product = () => import('@/views/product/index.vue')
const Contact = () => import('@/views/contact/index.vue')
const productLibrary = () => import('@/views/productLibrary/index.vue')
const order = () => import('@/views/order/index.vue')
const othercontact = () => import('@/views/othercontact/index.vue')

const Example = () => import('@/views/example/index.vue')  // 本系统所有组件和规范的示例说明页面

const Articlesystem = () => import('@/views/articlesystem/index.vue')  // 热门话题

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    meta: {
      title: '首页',
      isAuth: true
    },
    children: [
      {
        path: '/',
        name: 'Staging',
        meta: {
          title: '工作台',
          isAuth: true
        },
        component: Staging
      },
      {
        path: '/system',
        name: 'system',
        meta: {
          title: '系统配置',
          isAuth: true
        },
        component: Advert,
        children: [
          {
            path: 'productClassification',
            name: 'productClassification',
            meta: {
              title: '产品分类',
              isAuth: true
            },
            component: productClassification
          },
          {
            path: 'bannerManagement',
            name: 'bannerManagement',
            meta: {
              title: 'banner管理',
              isAuth: true
            },
            component: bannerManagement
          }, {
            path: 'classification',
            name: 'classification',
            meta: {
              title: '其他配置',
              isAuth: true
            },
            component: classification
          }
        ]
      }, {
        path: '/userlist',
        name: 'userlist',
        meta: {
          title: '用户管理',
          isAuth: true
        },
        component: Userlist
      }, {
        path: '/theme',
        name: 'theme',
        meta: {
          title: '主题管理',
          isAuth: true
        },
        component: Theme
      },
      // {
      //   path:'/advert',
      //   name:'advert',
      //   meta:{
      //     title:'广告推广',
      //     isAuth:true
      //   },
      //   component:Advert,
      //   children:[
      //     {
      //       path:'advertlist',
      //       name:'advertlist',
      //       meta:{
      //         title:'广告位管理',
      //         isAuth:true
      //       },
      //       component:AdvertList
      //     },
      //     {
      //       path:'managerlist',
      //       name:'managerlist',
      //       meta:{
      //         title:'广告管理',
      //         isAuth:true
      //       },
      //       component:AdvertManager
      //     }

      //   ]
      // },
      {
        path: '/catlist',
        name: 'catlist',
        meta: {
          title: '猫咪管理',
          isAuth: true
        },
        component: Catlist
      }, {
        path: '/product',
        name: 'product',
        meta: {
          title: '货品管理',
          isAuth: true
        },
        component: Product
      }, {
        path: '/articlesystem',
        name: 'articlesystem',
        meta: {
          title: '热门话题',
          isAuth: true
        },
        component: Articlesystem
      }, {
        path: '/contact',
        name: 'contact',
        meta: {
          title: '联系我们',
          isAuth: true
        },
        component: Contact
      }, {
        path: '/othercontact',
        name: 'othercontact',
        meta: {
          title: '联系我们',
          isAuth: true
        },
        component: othercontact
      },
      // {
      //   path:'/example',
      //   name:'example',
      //   meta:{
      //     title:'组件示例',
      //     isAuth:true
      //   },
      //   component:Example
      // },
      // {
      //   path:'/productLibrary',
      //   name:'productLibrary',
      //   meta:{
      //     title:'产品库',
      //     isAuth:true
      //   },
      //   component:productLibrary
      // },
      {
        path: '/order',
        name: 'order',
        meta: {
          title: '订单管理',
          isAuth: true
        },
        component: order
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
]

const router = new VueRouter({
  routes
})
// TODO 路由重定向有问题  首次登录，第一次因为异步问题获取不到token，暂时用setimeout解决
router.beforeEach((to, from, next) => {
  // 检查用户是否已登录
  setTimeout(() => {
    if (!getToken()) {
      // 如果用户未登录且有需要登录才能访问的路由，重定向到登录页面
      if (to.matched.some(record => record.meta.isAuth)) {
        next({ path: '/login' }); // 这里的path是你要重定向的路径
      } else {
        next(); // 如果不需要认证，继续导航
      }
    } else {
      next(); // 如果用户已登录，继续导航
    }
  }, 100);
});

export default router
