<template>
  <div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#6a68f0"
      text-color="#fff"
      active-text-color="#fff"
      router
    >
      <el-menu-item index="/">
        <i class="iconfont icon-gongzuotai"></i>
        <span slot="title">工作台</span>
      </el-menu-item>
      <el-submenu index="/system">
        <template slot="title">
          <i class="iconfont icon-neirongguanli-01"></i>
          <span>系统配置</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/system/productClassification"
            >产品分类</el-menu-item
          >
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/system/bannerManagement"
            >BANNER管理</el-menu-item
          >
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/system/classification">其他配置</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item index="/userlist">
        <i class="iconfont icon-yonghuguanli"></i>
        <span slot="title">用户管理</span>
      </el-menu-item>
      <el-menu-item index="/theme">
        <i class="iconfont icon-gongzuotai"></i>
        <span slot="title">主题管理</span>
      </el-menu-item>
      <!-- <el-submenu index="/advert">
        <template slot="title">
            <i class="iconfont icon-neirongguanli-01"></i>
             <span>广告推广</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/advert/advertlist">推广位置</el-menu-item>
          <el-menu-item index="/advert/managerlist">广告管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->
      <el-menu-item index="/catlist">
        <i class="iconfont icon-gongzuotai"></i>
        <span slot="title">猫咪管理</span>
      </el-menu-item>
      <el-menu-item index="/product">
        <i class="iconfont icon-xiti-01"></i>
        <span slot="title">货品管理</span>
      </el-menu-item>
      <el-menu-item index="/articlesystem">
        <i class="iconfont icon-xiti-01"></i>
        <span slot="title">热门话题</span>
      </el-menu-item>
      <el-menu-item index="/contact" v-if="userinfo.id == 3">
        <i class="iconfont icon-kecheng-01"></i>
        <span slot="title">联系我们</span>
      </el-menu-item>
      <el-menu-item index="/othercontact" v-else>
        <i class="iconfont icon-kecheng-01"></i>
        <span slot="title">联系我们</span>
      </el-menu-item>
      <!-- <el-menu-item index="/example">
        <i class="iconfont icon-quanbudingdan"></i>
        <span slot="title">组件示例</span>
      </el-menu-item> 
      <el-menu-item index="/productLibrary">
        <i class="iconfont icon-quanbudingdan"></i>
        <span slot="title">产品库</span>
      </el-menu-item>-->
      <el-menu-item index="/order">
        <i class="iconfont icon-quanbudingdan"></i>
        <span slot="title">订单管理</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "leftmenus",
  data() {
    return {
      userinfo: JSON.parse(localStorage.getItem("userinfo")),
    };
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-menu {
  border-right: 0;
  .iconfont {
    font-size: 20px;
    margin-right: 10px;
  }
}
.menus_title {
  display: block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
</style>