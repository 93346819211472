<template>
  <div class="login_template">
    <div class="login">
      <img src="../../assets/images/logo.png" class="login_logo" />
      <h2>最难得的坚持</h2>
      <div class="loginForm" v-if="!isReguesr">
        <el-form v-model="form">
          <el-input
            prefix-icon="el-icon-user"
            placeholder="请输入用户名"
            class="loginForm_input"
            v-model="form.phone"
          ></el-input>
          <el-input
            prefix-icon="el-icon-unlock"
            placeholder="请输入密码"
            class="loginForm_input"
            type="password"
            v-model="form.password"
          ></el-input>
        </el-form>
      </div>

      <div class="loginForm" v-else>
        <el-form v-model="reguesrForm">
          <el-input
            prefix-icon="el-icon-user"
            placeholder="请输入手机号"
            class="loginForm_input"
            v-model="reguesrForm.phone"
          ></el-input>
          <el-input
            prefix-icon="el-icon-unlock"
            placeholder="请输入密码"
            class="loginForm_input"
            type="password"
            v-model="reguesrForm.password"
          ></el-input>
          <el-input
            prefix-icon="el-icon-unlock"
            placeholder="请重复输入密码"
            class="loginForm_input"
            type="password"
            v-model="reguesrForm.repassword"
          ></el-input>
        </el-form>
      </div>

      <div class="rember">
        <p @click="changeLogin">没有账号？请注册</p>
        <p @click="forgot">忘记密码？</p>
      </div>
      <div class="login_button">
        <el-button type="primary" @click="login">{{
          isReguesr ? "注册" : "登录"
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { login, reguesr, myuserinfo } from "@/api/webapi";
import { setToken } from "@/utils/auth";
export default {
  name: "login",
  data() {
    return {
      form: {
        phone: "admin",
        password: "123456",
      },
      reguesrForm: {
        phone: "13104460996",
        password: "123456",
        repassword: "123456",
      },
      isReguesr: false,
      redirect: undefined,
      otherQuery: {},
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 登录和注册的切换
    changeLogin() {
      this.isReguesr = !this.isReguesr;
    },
    // 忘记密码
    forgot() {
      this.$confirm("忘记密码，请拨打管理员电话18744180221", "提示", {
        confirmButtonText: "ok",
        showCancelButton: false,
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
    // 登录和注册按钮  通过isReguesr判断
    login() {
      if (this.isReguesr) {
        // 注册
        if (!this.reguesrForm.phone) return this.$message.error("请输入手机号");
        if (!this.reguesrForm.password)
          return this.$message.error("请输入密码");
        if (!this.reguesrForm.repassword)
          return this.$message.error("请重复密码");
        if (this.reguesrForm.password !== this.reguesrForm.repassword)
          return this.$message.error("两次密码不一致");
        reguesr(this.reguesrForm)
          .then((res) => {
            if (res.status == 0) {
              this.$message.success("注册成功");
              this.isReguesr = false;
            }
          })
          .catch((err) => {
            console.log("注册接口报错~~~~", err);
          });
      } else {
        // 登录
        if (!this.form.phone) return this.$message.error("请输入手机号");
        if (!this.form.password) return this.$message.error("请输入密码");
        login(this.form)
          .then((res) => {
            if (res.status == 0) {
              console.log(
                "%c [  ]-139",
                "font-size:13px; background:pink; color:#bf2c9f;"
              );
              this.$router.push({
                path: this.redirect || "/",
                query: this.otherQuery,
              });
              setToken(res.token);

              myuserinfo().then((res) => {
                if (res.status == 0) {
                  localStorage.setItem("userinfo", JSON.stringify(res.data));
                }
              });
            }
          })
          .catch((err) => {
            console.log("登录接口报错~~~~", err);
          });
      }
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style>
</style>