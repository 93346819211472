import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { getToken } from './utils/auth'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/style.css'


Vue.config.productionTip = false
Vue.use(ElementUI);
//引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
//引入vue-quill-editor相关样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
