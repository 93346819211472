import request from '@/utils/request'

// 登录接口
export function login(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  })
}
// 七牛云登录
export function getQniuToken() {
  return request({
    url: '/api/get7Token',
    method: 'get',
  })
}
// 注册接口
export function reguesr(data) {
  return request({
    url: '/api/reguesr',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  })
}
// 获取用户列表
export function userList(data) {
  return request({
    url: '/my/userList',
    method: 'get',
    params: data,
  })
}


// 获取用户基本信息
export function userinfo(query) {
  return request({
    url: '/my/userinfo',
    method: 'get',
    params: query,

  })
}

// 获取当前账户的基本信息
export function myuserinfo() {
  return request({
    url: '/my/myuserinfo',
    method: 'get',
  })
}


//  修改用户信息
export function updateUserInfo(data) {
  return request({
    url: '/my/updateUserInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },

  })
}

// 通用上传图片接口
export function update(data) {
  return request({
    url: '/upload/upload',
    method: 'post',
    data,
    type: 'multipart/form-data'
  })
}

// 主题列表
export function themelist(data) {
  return request({
    url: '/theme/list',
    method: 'get',
    params: data,
  })
}

// 获取产品分类

export function getlistbykey(data) {
  return request({
    url: '/classification/getlistbykey',
    method: 'get',
    params: data,
  })
}

export function addTheme(data) {
  return request({
    url: '/theme/add',
    method: 'post',
    data,
  })
}
export function updateTheme(data) {
  return request({
    url: '/theme/update',
    method: 'post',
    data,
  })
}
export function deleteTheme(data) {
  return request({
    url: '/theme/delete',
    method: 'delete',
    data,
  })
}
export function detailsTheme(data) {
  return request({
    url: '/theme/details',
    method: 'get',
    params: data,
  })
}


// 分类管理
export function classificationlist(data) {
  return request({
    url: '/classification/list',
    method: 'get',
    params: data,
  })
}

export function addclassification(data) {
  return request({
    url: '/classification/add',
    method: 'post',
    data,
  })
}
export function updateclassification(data) {
  return request({
    url: '/classification/update',
    method: 'post',
    data,
  })
}
export function deleteclassification(data) {
  return request({
    url: '/classification/delete',
    method: 'delete',
    data,
  })
}
export function detailsclassification(data) {
  return request({
    url: '/classification/details',
    method: 'get',
    params: data,
  })
}




// 猫咪列表
export function catslist(data) {
  return request({
    url: '/cats/list',
    method: 'get',
    params: data,
  })
}
export function addCats(data) {
  return request({
    url: '/cats/add',
    method: 'post',
    data,
  })
}
export function updateCats(data) {
  return request({
    url: '/cats/update',
    method: 'post',
    data,
  })
}
export function deleteCats(data) {
  return request({
    url: '/cats/delete',
    method: 'delete',
    data,
  })
}
export function detailsCats(data) {
  return request({
    url: '/cats/details',
    method: 'get',
    params: data,
  })
}


// 货品列表
export function productlist(data) {
  return request({
    url: '/product/list',
    method: 'get',
    params: data,
  })
}

export function addProduct(data) {
  return request({
    url: '/product/add',
    method: 'post',
    data,
  })
}
export function updateProduct(data) {
  return request({
    url: '/product/update',
    method: 'post',
    data,
  })
}
export function deleteProduct(data) {
  return request({
    url: '/product/delete',
    method: 'delete',
    data,
  })
}
export function detailsProduct(data) {
  return request({
    url: '/product/details',
    method: 'get',
    params: data,
  })
}

// 联系我们

export function contactlist(data) {
  return request({
    url: '/contact/list',
    method: 'get',
    params: data,
  })
}
export function detailsContact(data) {
  return request({
    url: '/contact/details',
    method: 'get',
    params: data,
  })
}
export function updateContact(data) {
  return request({
    url: '/contact/update',
    method: 'post',
    data,
  })
}

// 订单管理

export function orderlist(data) {
  return request({
    url: '/order/alllist',
    method: 'get',
    params: data,
  })
}
export function updatestatus(data) {
  return request({
    url: '/order/updatestatus',
    method: 'post',
    data,
  })
}


// 添加规格
export function addskukey(data) {
  return request({
    url: '/other/addskukey',
    method: 'post',
    data,
  })
}
// 查看规格
export function getskudata(data) {
  return request({
    url: '/other/getskudata',
    method: 'get',
    params: data
  })
}
// 删除规格
export function deleteSku(data) {
  return request({
    url: '/other/deleteSku',
    method: 'delete',
    data
  })
}
// 编辑规格
export function updatesku(data) {
  return request({
    url: '/other/updatesku',
    method: 'post',
    data
  })
}

// 文章系统

export function getArticlesystemlist(data) {
  return request({
    url: '/articlesystem/list',
    method: 'get',
    params: data
  })
}

export function getArticlesystemdetails(data) {
  return request({
    url: '/articlesystem/details',
    method: 'get',
    params: data
  })
}

export function addArticlesystem(data) {
  return request({
    url: '/articlesystem/add',
    method: 'post',
    data
  })
}

export function updateArticlesystem(data) {
  return request({
    url: '/articlesystem/update',
    method: 'post',
    data
  })
}

export function deleteArticlesystem(data) {
  return request({
    url: '/articlesystem/delete',
    method: 'delete',
    data
  })
}

// 工作台接口
export function statisticalReportqueryAll(data) {
  return request({
    url: '/statisticalReport/queryAll',
    method: 'get',
    params: data
  })
}


