<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    // document.title = "喵乐送管理后台";
  },
};
</script>
<style lang="less">
</style>
