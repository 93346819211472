<template>
  <div class="layout">
    <div class="layout_left">
      <div class="layout_user">
        <div class="layout_user_tx">
          <img :src="baseUrl + userinfo.logourl" />
        </div>
        <h2>{{ userinfo.username }}</h2>
      </div>
      <div class="leftMenu_Content">
        <leftMenu></leftMenu>
      </div>
    </div>
    <div class="layout_right">
      <div class="layout_right_header">
        <el-page-header
          @back="goBack"
          :content="$route.meta.title"
        ></el-page-header>
        <span @click="logout"
          ><i class="iconfont icon-tuichu2"></i>退出系统</span
        >
      </div>
      <div class="appContent">
        <Container></Container>
      </div>
    </div>
  </div>
</template>
<script>
import leftMenu from "./menu/index.vue";
import Container from "./content/index.vue";
import { removeToken } from "@/utils/auth";
export default {
  name: "layout",
  components: {
    leftMenu,
    Container,
  },
  data() {
    return {
      userinfo: JSON.parse(localStorage.getItem("userinfo")),
      // baseUrl: process.env.VUE_APP_IMG_API + "/public/",
      baseUrl: "https://img.cathouse.work/",
    };
  },
  created() {
    console.log(
      "%c [  ]-43",
      "font-size:13px; background:pink; color:#bf2c9f;",
      process.env.VUE_APP_BASE_API
    );
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    logout() {
      removeToken();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>